'use client';

import {
  DropdownMenu,
  // DropdownMenuList,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuContent,
  // DropdownMenuItem,
  // DropdownMenuTriggerStyle,
} from '@demeter/shadcn-ui';
import { Menu as MenuIcon } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import * as React from 'react';

/**
 * Renders a single list item in the navigation menu.
 *
 * @example
 * ```tsx
 * <ListItem href="/page" title="Example Title">
 *   Short descriptive text
 * </ListItem>
 * ```
 *
 * @param props - An object containing the link title, children (description), and href.
 * @returns A list item that navigates to the specified href.
 * @throws No specific errors are thrown by this function.
 */
function ListItem({
  title,
  children,
  href,
}: {
  title: string;
  children: React.ReactNode;
  href: string;
}) {
  return (
    <li>
      <DropdownMenuItem asChild>
        <Link href={href} legacyBehavior passHref>
          <a className="hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors">
            <div className="mb-1 text-sm font-bold leading-none">{title}</div>
            <p className="text-muted-foreground text-sm leading-snug">
              {children}
            </p>
          </a>
        </Link>
      </DropdownMenuItem>
    </li>
  );
}

/**
 * Renders the site header with a lucide-react hamburger menu on the left side.
 * Clicking the icon opens a dropdown menu that displays navigation links. This
 * component replicates the layout style of the original shadcn UI example by
 * using a grid list with items that display a title and a short descriptive
 * paragraph below it.
 *
 * @example
 * ```tsx
 * import { NpoHeader } from '.';
 *
 * export default function Page() {
 *   return (
 *     <>
 *       <NpoHeader />
 *       <main>Content goes here</main>
 *     </>
 *   );
 * }
 * ```
 *
 * @returns A JSX element representing the site header.
 * @throws No specific errors are thrown by this function.
 */
export function NpoHeader() {
  return (
    // <header className="bg-background/95 supports-[backdrop-filter]:bg-background/60 flex-center fixed top-0 z-50 flex w-full items-center border-b backdrop-blur">
    <header className="bg-background flex-center fixed top-0 z-50 flex w-full items-center border-b backdrop-blur">
      <div className="flex h-[50px] items-center justify-start gap-2 pl-4">
        <DropdownMenu>
          {/* <DropdownMenuList> */}
          {/* <DropdownMenuItem> */}
          <DropdownMenuTrigger
            className="flex items-center justify-center outline-none"
            aria-label="Open Menu"
          >
            <MenuIcon className="h-6 w-6" />
          </DropdownMenuTrigger>
          {/* 
                Replicate the original shadcn layout by using a grid list.
                A fixed width helps prevent text overflow, and space-y classes
                provide gaps between items. 
              */}
          <DropdownMenuContent className="p-3" sideOffset={12}>
            <ul className="grid w-[94vw] gap-2 md:w-[400px] lg:w-[500px]">
              <ListItem href="/" title="Home">
                Visit the homepage for an overview of our platform and the
                latest updates.
              </ListItem>
              <ListItem href="/tallahassee" title="View Events">
                View a list of all the upcoming live music events in the
                Tallahassee area.
              </ListItem>
              <ListItem href="/npo/issues" title="Issues & Solutions">
                Learn about the major challenges we address and discover our
                innovative solutions.
              </ListItem>
              <ListItem href="/npo/approach" title="Approach">
                Explore our unique method of driving social change and fostering
                community impact.
              </ListItem>
            </ul>
          </DropdownMenuContent>
          {/* </DropdownMenuItem> */}
          {/* </DropdownMenuList> */}
        </DropdownMenu>
        <Link href="/">
          <div className="flex items-center gap-2">
            <Image
              src="/musiclocal-icon-32.png"
              alt="MusicLocal Icon"
              className="rounded"
              width={32}
              height={32}
            />
            <span className="xs:block hidden">MusicLocal</span>
          </div>
        </Link>
      </div>
    </header>
  );
}
